<template>
  <LayoutAuthForm id="divLoginPhoneWrapper">
    <VueForm name="loginPhoneForm" id="loginPhoneForm" ref="form" :bodyPadding="['0', '20']">
      <VueHeadline level="1" appearanceLevel="2">Telefon Numarası ile Giriş Yap</VueHeadline>
      <VueSpacer level="20" />
      <VueInput
        name="phoneNumber"
        mask="5#########"
        id="phoneNumber"
        type="tel"
        validatorName="Telefon Numarası"
        placeholder="5-------"
        label="Telefon Numaranız"
        v-model="formModel.phoneNumber"
        autocomplete="tel"
        :messageOnTop="true"
        borderType="left-bordered"
        validationRules="required|numeric|max:10|min:10"
        nospace
      />
      <VueSpacer level="20" />
      <VueInput
        :type="passwordType"
        name="passwordPhone"
        id="passwordPhone"
        validatorName="Şifre"
        label="Şifreniz"
        v-model="formModel.password"
        :messageOnTop="true"
        borderType="left-bordered"
        validationRules="required|max:20|min:1"
      >
        <VueButton
          :contentAlignment="constants.flexAlignment.center"
          :ghost="true"
          as="a"
          :iconName="getIcon.eye.name"
          :iconSize="{ width: getIcon.eye.width, height: getIcon.eye.height }"
          @click.prevent="togglePassword"
        ></VueButton>
      </VueInput>
      <VueSpacer level="30" />
      <VueGridRow hAlignment="between">
        <VueGridCol :size="6" alignment="center">
          <VueIcon
            class="button-active"
            v-show="rememberMe"
            :iconName="getIcon.tick.name"
            :iconSize="{ width: getIcon.tick.width, height: getIcon.tick.height }"
          />
          <VueButton
            :outlined="true"
            :contentAlignment="constants.flexAlignment.center"
            :ghost="true"
            @click.prevent="toggleRemember"
            as="a"
            :iconName="getIcon.circle.name"
            :iconSize="{ width: getIcon.circle.width, height: getIcon.circle.height }"
            :iconVisibility="!rememberMe ? true : false"
            >Beni Hatırla</VueButton
          >
        </VueGridCol>
        <VueGridCol :size="6" alignment="center">
          <VueButton
            :outlined="true"
            :contentAlignment="constants.flexAlignment.center"
            type="button"
            :ghost="true"
            as="router-link"
            :target="`${routes.login.FirstLogin.path}?header=2`"
            :iconName="getIcon.questionMark.name"
            :iconSize="{ width: getIcon.questionMark.width, height: getIcon.questionMark.height }"
            >Şifremi Unuttum</VueButton
          >
        </VueGridCol>
      </VueGridRow>
      <div class="gtm-ad-slot" id="div-gpt-ad-1587047449200-0"></div>
      <div slot="footer">
        <BrandButton
          :disabled="isDisabled"
          class="btn-login-submit"
          :size="sizes.xxLarge"
          :contentAlignment="constants.flexAlignment.center"
          type="submit"
          @click.prevent="handleLoginRequest"
          >GİRİŞ</BrandButton
        >
      </div>
    </VueForm>
  </LayoutAuthForm>
</template>
<script>
import RoutesAthentication from '@/router/routes/RoutesAthentication.js';
import RoutesLogin from '@/router/routes/authentication/RoutesLogin.js';
import LayoutAuthForm from '@/views/layouts/LayoutAuthForm.vue';
import VueInput from '@/components/shared/VueInput/VueInput.vue';
import VueButton from '@/components/shared/VueButton/VueButton.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import VueSpacer from '@/components/shared/VueSpacer/VueSpacer.vue';
import VueForm from '@/components/shared/VueForm/VueForm.vue';
import VueGridRow from '@/components/shared/VueGridRow/VueGridRow.vue';
import VueGridCol from '@/components/shared/VueGridCol/VueGridCol.vue';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import StorageHelper from '@/utils/storageHelper';
import LoginMixin from '@/mixins/login.js';
import gtmUtils from '@/mixins/gtmUtils.js';

export default {
  name: 'PhoneNumber',
  components: {
    LayoutAuthForm,
    VueInput,
    VueButton,
    BrandButton,
    VueIcon,
    VueHeadline,
    VueSpacer,
    VueForm,
    VueGridRow,
    VueGridCol,
  },
  mixins: [LoginMixin, gtmUtils],
  data: () => {
    return {
      isDisabled: true,
      formModel: {
        phoneNumber: '',
        password: '',
      },
      submitted: false,
      passwordType: 'password',
      rememberMe: false,
      selectablePosList: [],
      selectedPos: {},
      user: {},
    };
  },
  mounted() {
    this.pushAds('small');
  },
  computed: {
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
    routes() {
      return {
        auth: RoutesAthentication,
        login: RoutesLogin,
      };
    },
    getIcon() {
      return {
        eye: ICON_VARIABLES.eye,
        circle: ICON_VARIABLES.circle,
        questionMark: ICON_VARIABLES.questionMark,
        tick: ICON_VARIABLES.rememberMe,
      };
    },
  },
  watch: {
    formModel: {
      deep: true,
      handler() {
        if (this.formModel.phoneNumber !== '' && this.formModel.password !== '') {
          this.isDisabled = false;
        } else {
          this.isDisabled = true;
        }
      },
    },
  },
  methods: {
    togglePassword() {
      this.passwordType === 'password'
        ? (this.passwordType = 'text')
        : (this.passwordType = 'password');
    },
    toggleRemember() {
      this.rememberMe = !this.rememberMe;
    },
  },
  created() {
    const storageNo = new StorageHelper({
      id: process.env.VUE_APP_STORAGE_CURRENT_USER_PN_KEY,
    }).get(null, true);

    if (storageNo) {
      if (storageNo.timestamp - new Date().getTime() > 60 * 60 * 100 * 24) {
        this.formModel.phoneNumber = '';
      } else {
        this.formModel.phoneNumber = storageNo.data;
        this.rememberMe = storageNo.data ? true : false;
      }
    }
  },
};
</script>
<style scoped lang="scss">
.button-active {
  position: absolute;
  top: 1px;
}
.btn-login-submit {
  &[disabled] {
    background: palette-color-level('grey', '30');
  }
}
</style>
